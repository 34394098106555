import {
  DependencyList,
  EffectCallback,
  useCallback,
  useEffect,
  useState,
} from 'react';

type StatefulApiError = Record<string, unknown> | null;

export type StatefulApiReturnParams<R> = {
  data?: R;
  error: StatefulApiError;
  loading: boolean;
};

/**
 * A custom React hook for handling asynchronous API calls with state management.
 *
 * @template P - The type of parameters the API request function accepts
 * @template R - The type of response the API request returns
 *
 * @param {(params: P) => Promise<R>} req - The API request function that returns a Promise
 * @param {DependencyList} [deps] - Dependencies array that triggers the API call when changed
 * @param {(response: R, params: P) => void} [onSuccess] - Optional callback function called on successful API response
 * @param {(error: StatefulApiError) => void} [onError] - Optional callback function called when API request fails
 *
 * @returns {StatefulApiReturnParams<R>} An object containing:
 *   - data: The API response data
 *   - error: Any error that occurred during the request
 *   - loading: Boolean indicating if the request is in progress
 *
 * @example
 * const { data, loading, error } = useStatefulApi(
 *   (params) => api.fetchData(params),
 *   [dependencyValue],
 *   (response) => console.log('Success:', response),
 *   (error) => console.error('Error:', error)
 * );
 */
export const useStatefulApi = <P, R>(
  req: (params: P) => Promise<R>,
  deps?: DependencyList,
  onSuccess?: (response: R, params: P) => void,
  onError?: (error: StatefulApiError) => void,
): StatefulApiReturnParams<R> => {
  const [data, setData] = useState<R>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<StatefulApiError>(null);

  const sendRequest = useCallback(
    (params: P) => {
      setError(null);
      setLoading(true);
      req(params)
        .then((response: R) => {
          setData(response);
          setLoading(false);
          if (onSuccess) {
            onSuccess(response, params);
          }
        })
        .catch((reject: StatefulApiError) => {
          setError(reject);
          setLoading(false);
          if (onError) {
            onError(reject);
          }
        });
    },
    [onError, onSuccess, req],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(sendRequest as EffectCallback, deps || []);

  return { data, loading, error };
};
