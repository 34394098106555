import { Icon, IconProps, useColorMode } from '@chakra-ui/react';

interface CustomIconProps extends IconProps {
  isSelected?: boolean;
}

const getStrokeColor = (isSelected: boolean | undefined, colorMode: string) => {
  if (isSelected) return 'currentColor';
  return colorMode === 'dark' ? 'white' : 'currentColor';
};

export function CoreIcon(props: CustomIconProps) {
  const { colorMode } = useColorMode();
  const { isSelected, ...rest } = props;
  const strokeColor = getStrokeColor(isSelected, colorMode);

  return (
    <Icon viewBox="0 0 24 24" {...rest}>
      <circle
        cx="12"
        cy="12"
        r="1"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M20.2 20.2c2.04-2.03.02-7.36-4.5-11.9-4.54-4.52-9.87-6.54-11.9-4.5-2.04 2.03-.02 7.36 4.5 11.9 4.54 4.52 9.87 6.54 11.9 4.5Z"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M15.7 15.7c4.52-4.54 6.54-9.87 4.5-11.9-2.03-2.04-7.36-.02-11.9 4.5-4.52 4.54-6.54 9.87-4.5 11.9 2.03 2.04 7.36.02 11.9-4.5Z"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
    </Icon>
  );
}

export function RetailIcon(props: CustomIconProps) {
  const { colorMode } = useColorMode();
  const { isSelected, ...rest } = props;
  const strokeColor = getStrokeColor(isSelected, colorMode);

  return (
    <Icon viewBox="0 0 24 24" {...rest}>
      <path
        d="m2 7 4.41-4.41A2 2 0 0 1 7.83 2h8.34a2 2 0 0 1 1.42.59L22 7"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M15 22v-4a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v4"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M2 7h20"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M22 7v3a2 2 0 0 1-2 2a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 16 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 12 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 8 12a2 2 0 0 1-2-2V7"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
    </Icon>
  );
}

export function LeisureIcon(props: CustomIconProps) {
  const { colorMode } = useColorMode();
  const { isSelected, ...rest } = props;
  const strokeColor = getStrokeColor(isSelected, colorMode);

  return (
    <Icon viewBox="0 0 24 24" {...rest}>
      <path
        d="M2 10s3-3 3-8"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M22 10s-3-3-3-8"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M10 2c0 4.4-3.6 8-8 8"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M14 2c0 4.4 3.6 8 8 8"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M2 10s2 2 2 5"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M22 10s-2 2-2 5"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M8 15h8"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M2 22v-1a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M14 22v-1a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
    </Icon>
  );
}

export function ProfessionalIcon(props: CustomIconProps) {
  const { colorMode } = useColorMode();
  const { isSelected, ...rest } = props;
  const strokeColor = getStrokeColor(isSelected, colorMode);

  return (
    <Icon viewBox="0 0 24 24" {...rest}>
      <path
        d="M12 12h.01"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M16 6V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M22 13a18.15 18.15 0 0 1-20 0"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <rect
        width="20"
        height="14"
        x="2"
        y="6"
        rx="2"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
    </Icon>
  );
}

export function HospitalityIcon(props: CustomIconProps) {
  const { colorMode } = useColorMode();
  const { isSelected, ...rest } = props;
  const strokeColor = getStrokeColor(isSelected, colorMode);

  return (
    <Icon viewBox="0 0 24 24" {...rest}>
      <path
        d="M12 3V2"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="m15.4 17.4 3.2-2.8a2 2 0 1 1 2.8 2.9l-3.6 3.3c-.7.8-1.7 1.2-2.8 1.2h-4c-1.1 0-2.1-.4-2.8-1.2l-1.302-1.464A1 1 0 0 0 6.151 19H5"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M2 14h12a2 2 0 0 1 0 4h-2"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M4 10h16"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M5 10a7 7 0 0 1 14 0"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M5 14v6a1 1 0 0 1-1 1H2"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
    </Icon>
  );
}

export function FoodAndDrinkIcon(props: CustomIconProps) {
  const { colorMode } = useColorMode();
  const { isSelected, ...rest } = props;
  const strokeColor = getStrokeColor(isSelected, colorMode);

  return (
    <Icon viewBox="0 0 24 24" {...rest}>
      <path
        d="M8 2h8"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M9 2v2.789a4 4 0 0 1-.672 2.219l-.656.984A4 4 0 0 0 7 10.212V20a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-9.789a4 4 0 0 0-.672-2.219l-.656-.984A4 4 0 0 1 15 4.788V2"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M7 15a6.472 6.472 0 0 1 5 0 6.47 6.47 0 0 0 5 0"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
    </Icon>
  );
}

export function FinancialServicesIcon(props: CustomIconProps) {
  const { colorMode } = useColorMode();
  const { isSelected, ...rest } = props;
  const strokeColor = getStrokeColor(isSelected, colorMode);

  return (
    <Icon viewBox="0 0 24 24" {...rest}>
      <path
        d="M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M10 6h4"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M10 10h4"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M10 14h4"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <path
        d="M10 18h4"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
    </Icon>
  );
}

export function PublicSectorIcon(props: CustomIconProps) {
  const { colorMode } = useColorMode();
  const { isSelected, ...rest } = props;
  const strokeColor = getStrokeColor(isSelected, colorMode);

  return (
    <Icon viewBox="0 0 24 24" {...rest}>
      <line
        x1="3"
        x2="21"
        y1="22"
        y2="22"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <line
        x1="6"
        x2="6"
        y1="18"
        y2="11"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <line
        x1="10"
        x2="10"
        y1="18"
        y2="11"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <line
        x1="14"
        x2="14"
        y1="18"
        y2="11"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <line
        x1="18"
        x2="18"
        y1="18"
        y2="11"
        stroke={strokeColor}
        strokeWidth="1"
      />
      <polygon
        points="12 2 20 7 4 7"
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />
    </Icon>
  );
}

export const getIconForProduct = (filter: string) => {
  switch (filter) {
    case 'Core':
      return CoreIcon;
    case 'Retail':
      return RetailIcon;
    case 'Leisure':
      return LeisureIcon;
    case 'Professional_Services':
      return ProfessionalIcon;
    case 'Hospitality':
      return HospitalityIcon;
    case 'Food_Bevarage_Manufacturing':
      return FoodAndDrinkIcon;
    case 'Financial_Services':
      return FinancialServicesIcon;
    case 'Public_Sector':
      return PublicSectorIcon;
    default:
      return CoreIcon;
  }
};
