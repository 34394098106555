import { createElement, useContext } from 'react';
import {
  Box,
  AccordionIcon,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Grid,
  GridItem,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { AnalyticsContext } from 'context/analyticsContext';
import { getIconForProduct } from './productIcons';

type ProductsSelectorProps = {
  selectedProducts?: string[];
  setSelectedProducts: (value: string[]) => void;
  setOffset: (value: number) => void;
  usersProducts: string[];
};

const productNames: { [key: string]: string } = {
  Core: 'Core',
  Retail: 'Retail',
  Professional_Services: 'Professional Services',
  Hospitality: 'Hospitality',
  Food_Bevarage_Manufacturing: 'Food & Beverage Manufacturing',
  Financial_Services: 'Financial Services',
  Public_Sector: 'Public Sector',
  Property: 'Property',
  Construction: 'Construction',
  Recruitment: 'Recruitment',
  Health_Social_Care: 'Health & Social Care',
};

function ProductsSelector({
  selectedProducts,
  setSelectedProducts,
  setOffset,
  usersProducts,
}: ProductsSelectorProps) {
  const sendAnalyticsEvent = useContext(AnalyticsContext);
  const textColor = useColorModeValue('black', 'white');

  const onSelect = (products: string[]) => {
    sendAnalyticsEvent({
      category: 'Products',
      action: `Suite changed`,
      label: `${products}`,
    });
    setSelectedProducts(products);
    setOffset(0);
  };
  const toggleFilter = (product: string) => {
    if (selectedProducts?.includes(product)) {
      onSelect(
        (selectedProducts ?? []).filter(
          (thisProduct) => thisProduct !== product,
        ),
      );
    } else {
      onSelect([...(selectedProducts ?? []), product]);
    }
  };

  return (
    <AccordionItem>
      <h2>
        <AccordionButton data-testid="buttonOpenFilters">
          <Box flex="1" textAlign="left">
            Suite
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Grid
          role="radiogroup"
          templateColumns={[
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
            'repeat(4, 1fr)',
            'repeat(2, 1fr)',
          ]}
          gap={3}
        >
          {usersProducts.map((product) => (
            <GridItem
              data-testid={`buttonFilter${product}`}
              onClick={() => toggleFilter(product)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  toggleFilter(product);
                }
              }}
              role="radio"
              aria-checked={selectedProducts?.includes(product)}
              tabIndex={0}
              aria-labelledby={`${product}-label`}
              borderColor={
                selectedProducts?.includes(product) ? 'blue.300' : 'grey.200'
              }
              borderWidth={selectedProducts?.includes(product) ? 2 : 1}
              height={20}
              key={`products-${product}`}
              borderRadius={5}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDir="column"
              cursor="pointer"
            >
              <Box display="inline-block" m={1.5}>
                {createElement(getIconForProduct(product), {
                  boxSize: 7,
                  isSelected: selectedProducts?.includes(product),
                  color: selectedProducts?.includes(product)
                    ? 'blue.500'
                    : textColor,
                })}
              </Box>
              <Text
                color={
                  selectedProducts?.includes(product) ? 'blue.500' : textColor
                }
                fontWeight={selectedProducts?.includes(product) ? 500 : 300}
                fontSize=".8rem"
                id={`${product}-label`}
                textAlign="center"
              >
                {productNames[product]}
              </Text>
            </GridItem>
          ))}
        </Grid>
      </AccordionPanel>
    </AccordionItem>
  );
}

export default ProductsSelector;
